.frequency-dropdown {
    height: 38.5px;
    width: 110px;
    min-width: unset !important;
    margin-left: 7px;
    margin-right: 7px;
}

.text {
    color: black;
    font-size: 16px;
}