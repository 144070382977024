#cronspeak-text {
  text-align: center;
  color: white;
  font-size: 100px;
  letter-spacing: 5px;
  padding-bottom: 4vh;
}

#slashies {
  color: black;
}

#wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #b621fe;
  background: linear-gradient(315deg, #b621fe 0%, #1fd1f9 74%);
  overflow: auto;
  padding-bottom: 4vh;
}
