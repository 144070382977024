#user-selection-wrapper {
    margin-top: 7vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-text {
    color: white;
    font-size: 22px;
    padding-right: 7px;
}

.start-button {
    margin-left: 5px;
    background: transparent;
    border: 0;
    transition: color 1 ease-in-out;
    font-size: 22px;
}

.start-button:hover {
    cursor: pointer;
    color: white;
}

.more-button {
    border: 0;
    background: transparent;
    transition: transform .2s ease-in-out;
}

.more-button:hover {
    transform: rotate(90deg);
    cursor: pointer;
}

.fewer-button {
    border: 0;
    background: transparent;
    transition: transform .2s ease-in-out;
}

.fewer-button:hover {
    transform: rotate(180deg);
    cursor: pointer;
}

.additional-text-wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}